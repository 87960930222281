import React, {Fragment} from 'react';
import Helmet from 'react-helmet';
import {Col} from 'jsxstyle';

import Layout from '@layouts';
import {BLUE, WHITE} from '@styles/colors';
import Headline from '@components/Headline';
import Paragraph from '@components/Paragraph';

const mediaQueries = {
  sm: 'screen and (max-width: 768px)',
  lg: 'screen and (min-width: 1280px)',
};

export default () => {
  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <title>{'Terms of Use'}</title>
        <meta name="description" content={'Terms of Use'} />
      </Helmet>
      <Layout menuItemColor={BLUE} menuBackground={WHITE}>
        <Col
          width="90%"
          margin="0 auto"
          alignItems="flex-start"
          mediaQueries={mediaQueries}
          smMargin="40px 0"
        >
          <Headline margin="80px 0 40px 0" textAlign="left">
            Website Terms of Use
          </Headline>
          <Headline textAlign="left">
            Effective Date: November 27, 2018
          </Headline>
          <Paragraph textAlign="left">
            These terms of use are entered into by and between You and Fetner
            Properties, Inc., its affiliates, subsidiaries, and related
            companies (collectively, the "Company", "we" or "us"). The following
            terms and conditions (these "Terms of Use"), govern your access to
            and use of any website which links to these Terms of Use, including
            any content, functionality and services offered on or through such
            website (each a "Website"), whether as a guest or a registered user.
          </Paragraph>
          <Paragraph textAlign="left">
            Please read the Terms of Use carefully before you start to use a
            Website. By using a Website, you accept and agree to be bound and
            abide by these Terms of Use. If you do not want to agree to these
            Terms of Use, you must not access or use a Website.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Consent for Communications
          </Headline>
          <Paragraph textAlign="left">
            By providing your telephone number, you are providing express
            written consent to receive communications from Fetner, (including
            its affiliates, agents, service providers, and affiliates for the
            purposes of defined above) for any purpose, including but not
            limited to marketing various services from both Fetner and companies
            Fetner has joint marketing agreements with. Additionally, you agree
            to receive communications from Fetner regarding your any service,
            and any information you may have obtained via your use of a Website.
            You agree that these communications include, but are not limited to,
            the use of an Automated Telephone Dialing System, prerecorded and/or
            artificial voice, SMS, MMS, text, fax, email or other similar means.
            Note, regardless of whether your phone number is registered on a
            state or federal Do Not Call list. You agree that Fetner is not
            responsible for any charges to you regarding these communications.
            Standard voice and data rates may apply. Further, you understand
            that you do not need to provide this consent to call as a condition
            to receive any good or service, in which case you will not provide
            your phone number.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Changes to the Terms of Use
          </Headline>
          <Paragraph textAlign="left">
            We may revise and update these Terms of Use from time to time in our
            sole discretion. All changes are effective immediately when we post
            them. Your continued use of the Website following the posting of
            revised Terms of Use means that you accept and agree to the changes.
            You are expected to check this page from time to time so you are
            aware of any changes, as they are binding on you.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Intellectual Property Rights
          </Headline>
          <Paragraph textAlign="left">
            Each Website and its entire contents, features and functionality
            (including, but not limited to, all information, software, text,
            displays, images, video and audio, and the design, selection and
            arrangement thereof), are owned by the Company, its licensors or
            other providers of such material and are protected by United States
            and international copyright, trademark, patent, trade secret and
            other intellectual property or proprietary rights laws.
          </Paragraph>
          <Paragraph textAlign="left">
            These Terms of Use permit you to use a Website for your personal,
            non-commercial use only. You must not reproduce, distribute, modify,
            create derivative works of, publicly display, publicly perform,
            republish, download, store or transmit any of the material on a
            Website, except as follows:
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Prohibited Uses
          </Headline>
          <Paragraph textAlign="left">
            You may use a Website only for lawful purposes and in accordance
            with these Terms of Use. You agree not to use a Website:
          </Paragraph>
          <ul
            style={{
              fontFamily: 'proxima-nova',
              fontSize: '1rem',
              lineHeight: '1.5rem',
              color: BLUE,
              listStyle: 'inside disc',
              marginBottom: '40px',
            }}
          >
            <li>
              In any way that violates any applicable federal, state, local or
              international law or regulation (including, without limitation,
              any laws regarding the export of data or software to and from the
              US or other countries)
            </li>
            <li>
              For the purpose of exploiting, harming or attempting to exploit or
              harm minors in any way by exposing them to inappropriate content,
              asking for personally identifiable information or otherwise.
            </li>
            <li>
              To send, knowingly receive, upload, download, use or re-use any
              material which does not comply with these Terms of Use.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material, including any "junk mail", "chain letter" or
              "spam" or any other similar solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate the Company, a Company
              employee, another user or any other person or entity (including,
              without limitation, by using e-mail addresses or screen names
              associated with any of the foregoing).
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of a Website, or which, as determined by us, may
              harm the Company or users of a Website or expose them to
              liability.
            </li>
          </ul>
          <Paragraph textAlign="left">
            Additionally, you agree not to:
          </Paragraph>
          <ul
            style={{
              fontFamily: 'proxima-nova',
              fontSize: '1rem',
              lineHeight: '1.5rem',
              color: BLUE,
              listStyle: 'inside disc',
              marginBottom: '40px',
            }}
          >
            <li>
              Use a Website in any manner that could disable, overburden,
              damage, or impair the site or interfere with any other party's use
              of a Website, including their ability to engage in real time
              activities through a Website.
            </li>
            <li>
              Use any robot, spider or other automatic device, process or means
              to access a Website for any purpose, including monitoring or
              copying any of the material on a Website.
            </li>
            <li>
              Use any manual process to monitor or copy any of the material on a
              Website or for any other unauthorized purpose without our prior
              written consent.
            </li>
            <li>
              Use any device, software or routine that interferes with the
              proper working of a Website.
            </li>
            <li>
              Introduce any viruses, trojan horses, worms, logic bombs or other
              material which is malicious or technologically harmful.
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage or
              disrupt any parts of a Website, the server on which a Website is
              stored, or any server, computer or database connected to a
              Website.
            </li>
            <li>
              Attack a Website via a denial-of-service attack or a distributed
              denial-of-service attack.
            </li>
            <li>
              Otherwise attempt to interfere with the proper working of a
              Website.
            </li>
          </ul>
          <Headline margin="40px 0" textAlign="left">
            Reliance on Information Posted
          </Headline>
          <Paragraph textAlign="left">
            The information presented on or through a Website is made available
            solely for general information purposes. We do not warrant the
            accuracy, completeness or usefulness of this information. Any
            reliance you place on such information is strictly at your own risk.
            We disclaim all liability and responsibility arising from any
            reliance placed on such materials by you or any other visitor to a
            Website, or by anyone who may be informed of any of its contents.
          </Paragraph>
          <Paragraph textAlign="left">
            The Websites may include content provided by third parties,
            including materials provided by other users, bloggers and
            third-party licensors, syndicators, aggregators and/or reporting
            services. All statements and/or opinions expressed in these
            materials, and all articles and responses to questions and other
            content, other than the content provided by the Company, are solely
            the opinions and the responsibility of the person or entity
            providing those materials. These materials do not necessarily
            reflect the opinion of the Company. We are not responsible, or
            liable to you or any third party, for the content or accuracy of any
            materials provided by any third parties.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Changes to the Website
          </Headline>
          <Paragraph textAlign="left">
            We may update the content on a Website from time to time, but its
            content is not necessarily complete or up-to-date. Any of the
            material on a Website may be out of date at any given time, and we
            are under no obligation to update such material.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Linking to the Website
          </Headline>
          <Paragraph textAlign="left">
            You may link to a Website, provided you do so in a way that is fair
            and legal and does not damage our reputation or take advantage of
            it, but you must not establish a link in such a way as to suggest
            any form of association, approval, or endorsement on our part
            without our express consent.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Links from the Website
          </Headline>
          <Paragraph textAlign="left">
            If a Website contains links to other sites and resources provided by
            third parties, these links are provided for your convenience only.
            This includes links contained in advertisements, including banner
            advertisements and sponsored links. We have no control over the
            contents of those sites or resources, and accept no responsibility
            for them or for any loss or damage that may arise from your use of
            them. If you decide to access any of the third party websites linked
            to a Website, you do so entirely at your own risk and subject to the
            terms and conditions of use for such websites.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Geographic Restrictions
          </Headline>
          <Paragraph textAlign="left">
            The owner of the Websites is based in the State of New York in the
            United States. We provide the Websites for use only by persons
            located in the United States. We make no claims that the Websites or
            any of their content is accessible or appropriate outside of the
            United States. Access to the Websites may not be legal by certain
            persons or in certain countries. If you access a Website from
            outside the United States, you do so on your own initiative and are
            responsible for compliance with local laws.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Disclaimer of Warranties
          </Headline>
          <Paragraph textAlign="left">
            You understand that we cannot and do not guarantee or warrant that
            files available for downloading from the internet or a Website will
            be free of viruses or other destructive code. You are responsible
            for implementing sufficient procedures and checkpoints to satisfy
            your particular requirements for anti-virus protection and accuracy
            of data input and output, and for maintaining a means external to
            our site for any reconstruction of any lost data. WE WILL NOT BE
            LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
            DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL
            MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
            DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR
            ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR
            DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED
            TO IT.
          </Paragraph>
          <Paragraph textAlign="left">
            YOUR USE OF A WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS
            OBTAINED THROUGH A WEBSITE IS AT YOUR OWN RISK. THE WEBSITES, THEIR
            CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES ARE
            PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
            WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE
            COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY
            WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
            SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE
            WEBSITES. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR
            ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
            WEBSITES, THEIR CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH
            THE WEBSITES WILL BE ACCURATE, RELIABLE, ERROR-FREE OR
            UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE WEBSITES OR
            THE SERVERS THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER
            HARMFUL COMPONENTS OR THAT THE WEBSITES OR ANY SERVICES OR ITEMS
            OBTAINED THROUGH THE WEBSITES WILL OTHERWISE MEET YOUR NEEDS OR
            EXPECTATIONS.
          </Paragraph>
          <Paragraph textAlign="left">
            THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
            EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
            LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND
            FITNESS FOR PARTICULAR PURPOSE.
          </Paragraph>
          <Paragraph textAlign="left">
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Limitation on Liability
          </Headline>
          <Paragraph textAlign="left">
            IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR RESPECTIVE
            LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, MANAGERS, OFFICERS
            OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL
            THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY
            TO USE, THE WEBSITES, ANY WEBSITES LINKED TO THE WEBSITES, ANY
            CONTENT ON THE WEBSITES OR SUCH OTHER WEBSITES OR ANY SERVICES OR
            ITEMS OBTAINED THROUGH THE WEBSITES OR SUCH OTHER WEBSITES,
            INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL
            OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY,
            PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF
            PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS
            OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
            NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
          </Paragraph>
          <Paragraph textAlign="left">
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Indemnification
          </Headline>
          <Paragraph textAlign="left">
            You agree to defend, indemnify and hold harmless the Company, its
            affiliates, licensors and service providers, and its and their
            respective managers, officers, directors, employees, contractors,
            agents, licensors, suppliers, successors and assigns from and
            against any claims, liabilities, damages, judgments, awards, losses,
            costs, expenses or fees (including reasonable attorneys' fees)
            arising out of or relating to your violation of these Terms of Use
            or your use of the Websites, including, but not limited to, your
            User Contributions, any use of the Websites’ content, services and
            products other than as expressly authorized in these Terms of Use or
            your use of any information obtained from the Websites.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Governing Law
          </Headline>
          <Paragraph textAlign="left">
            All matters relating to the Websites and these Terms of Use and any
            dispute or claim arising therefrom or related thereto (in each case,
            including non-contractual disputes or claims), shall be governed by
            and construed in accordance with the internal laws of the State of
            New York without giving effect to any choice or conflict of law
            provision or rule (whether of the State of New York or any other
            jurisdiction).
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Arbitration; Venue
          </Headline>
          <Paragraph textAlign="left">
            Any controversy or claim arising out of, or relating to, these Terms
            of Use, or breach thereof, shall be settled by binding arbitration
            in accordance with the governing law as determined by these Terms of
            Use, the Federal Arbitration Act (insofar as it governs the
            arbitrability of such controversy or claim), and the Commercial
            Arbitration Rules (excluding Expedited Procedures) of the American
            Arbitration Association in the City of New York, State of New York.
            Three qualified arbitrators shall be appointed in accordance with
            the Commercial Arbitration Rules (excluding Expedited Procedures) of
            the American Arbitration Association and these Terms of Use. Such
            qualified arbitrators shall be members of the State of New York bar
            and shall have at least five years of experience in the law relevant
            to the dispute in question. Each party shall have the right of
            discovery as set forth in the Federal Rules of Civil Procedure. A
            stenographer shall be present at the arbitration proceedings and the
            stenographic record shall be the official record of the proceeding.
            The arbitrators shall provide written findings of fact and
            conclusions of law in justification of any arbitration award. The
            Company shall have the right of appeal of any decision by the
            arbitrators by filing a request for reconsideration of any
            arbitration decision with the American Arbitration Association. Upon
            receiving such a request, the American Arbitration Association shall
            reconsider the matter de novo using the foregoing procedures. Should
            the arbitration award be inconsistent with the governing law as
            specified by these Terms of Use, the Company may immediately appeal
            the arbitration award to any court of competent jurisdiction over
            the Company. Judgment on the award rendered by the arbitrators may
            be entered in any court having competent jurisdiction over the
            parties, unless a subsequent request for reconsideration has been
            filed by the Company under this Section, or the award has been
            appealed to a court of competent jurisdiction under this Section.
          </Paragraph>
          <Paragraph textAlign="left">
            The parties waive any right to bring representative claims on behalf
            of a class of individuals, on behalf of the public, as a private
            attorney general, or otherwise (the “class action waiver”). Except
            for this class action waiver, this clause may be severed or modified
            if necessary to render it enforceable under the Federal Arbitration
            Act.
          </Paragraph>
          <Paragraph textAlign="left">
            Any legal suit, action or proceeding arising out of, or related to,
            these Terms of Use or the Website, and allowed pursuant to this
            Section, shall be instituted exclusively in the federal courts of
            the United States in the Borough of Manhattan, State of New York or
            the courts of the State of New York located in the Borough of
            Manhattan. You waive any and all objections to the exercise of
            jurisdiction over you by such courts and to venue in such courts.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Limitation on Time to File Claims
          </Headline>
          <Paragraph textAlign="left">
            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
            TO THESE TERMS OF USE OR THE WEBSITES MUST BE COMMENCED WITHIN ONE
            (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF
            ACTION OR CLAIM IS PERMANENTLY BARRED.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Waiver and Severability
          </Headline>
          <Paragraph textAlign="left">
            No waiver of any term or condition set forth in these Terms of Use
            by the Company shall be deemed a further or continuing waiver of
            such term or condition or a waiver of any other term or condition,
            and any failure of the Company to assert a right or provision under
            these Terms of Use shall not constitute a waiver of such right or
            provision.
          </Paragraph>
          <Paragraph textAlign="left">
            If any provision of these Terms of Use is held by a court or other
            tribunal of competent jurisdiction to be invalid, illegal or
            unenforceable for any reason, such provision shall be eliminated or
            limited to the minimum extent such that the remaining provisions of
            the Terms of Use will continue in full force and effect.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Entire Agreement
          </Headline>
          <Paragraph textAlign="left" margin="0 0 40px 0">
            These Terms of Use constitute the sole and entire agreement between
            you and the Company with respect to the Websites and supersede all
            prior and contemporaneous understandings, agreements,
            representations and warranties, both written and oral, with respect
            to the Websites.
          </Paragraph>
        </Col>
      </Layout>
    </Fragment>
  );
};
